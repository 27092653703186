import React, { ReactElement, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import styled, { css } from 'styled-components';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';

import currencies from 'data/currencies';

import theme, {bp, color} from 'settings/theme';

const ConnectWalletButton = dynamic(() => import('components/common/wallet/walletButton'), {
  ssr: false
});

const LAMPORTS_PER_SOL = 1000000000;


type Props = {};

const HeaderWallet: React.FC<Props> = (props) => {

  const { connected, connecting, publicKey } = useWallet();
  const {connection} = useConnection();
  const [balance, setBalance] = useState<string>('5');
  const [isBalanceChecking, setIsBalanceChecking] = useState(false);

  const fetchWalletBalance = async () => {
    if (connected && publicKey){
      
      setIsBalanceChecking(true);

      try {
        
        const { PublicKey } = (await import('@solana/web3.js'));
        const TOKEN_PROGRAM_ID = new PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA");

        setBalance('0');

        const parsedTokenAccounts = await connection.getParsedTokenAccountsByOwner(publicKey, {
          programId: TOKEN_PROGRAM_ID
        });

        const accounts = parsedTokenAccounts.value.map(account => account.account.data.parsed.info);
        const mint = currencies.find(x => x.tokenSymbol === 'usdc')?.mint;
        const balance = accounts.find(x => x.mint === mint)?.tokenAmount?.uiAmount ?? 0;
        setBalance(balance.toFixed(2));

      } catch (error) {

        // Handle balance load error

      }  finally {
        setIsBalanceChecking(false);
      }
      
    }
  }



  useEffect(() => {
    fetchWalletBalance(); 
  }, [publicKey]);

  const selectedCurrency = {symbol: 'usdc'};

  const getWalletLogoUrl = (walletName: string) => {

    switch (walletName?.toLowerCase()) {
      case 'phantom':
        return '/images/logos/logo-phantom-square.svg';
      case 'solflare':
        return '/images/logos/logo-solflare-square.svg';
      case 'slope':
        return '/images/logos/logo-slope-square.svg';
      default:
        return '/images/logos/logo-unknown-wallet-square.svg';
    }

  }

  return (
    <StyledHeaderWallet isConnected={connected} className="headerWallet">

      <ConnectWalletButton>
        {({connecting, connected, shortAddress, wallet}) => (
        
          <>
            {connecting ? (

                <span>
                  Connecting...
                </span>
                
              ) : !!wallet && connected ? (

                <div className="headerLarge-wallet-connected">
                  <div className="headerLarge-wallet-connected-logo">
                    <Image src={getWalletLogoUrl(wallet.adapter.name)} fill={true} sizes="0vw" alt="Wallet Logo" />
                  </div>
                  <div className="headerLarge-wallet-connected-details">
                    <div className="headerLarge-wallet-connected-balance">
                      {isBalanceChecking ? (
                        <>Checking...</>
                      ) : (
                        <>{balance} USDC</>
                      )}
                    </div>
                    <div className="headerLarge-wallet-connected-shortAddress">
                      {shortAddress}
                    </div>
                  </div>
                  
                </div>

              ) : (

                <div className="headerLarge-wallet-notConnected">
                  Connect Wallet
                </div>

              )
            }
          </>

        )}
        
      </ConnectWalletButton>

    </StyledHeaderWallet>
  );

};

export default HeaderWallet;

const StyledHeaderWallet = styled.div<{isConnected: boolean}>`

  --connect-wallet-button-height: auto;
  --connect-wallet-button-font-size: 0.75rem;
  --connect-wallet-button-color: ${color.gray(90)};
  --connect-wallet-button-hover-color: ${color.gray(98)};
  --connect-wallet-button-text-color: ${color.gray(15)};
  --connect-wallet-button-border-radius: 0.5rem;

  position: relative;
  height: 100%;

  .wallet-adapter-dropdown{
    height: 100%;
  }

  button{

    justify-content: center;
    width: 100%;
    padding: 0;

    .headerLarge-wallet-notConnected{
      display: flex;
      align-items: center;
      height: 2.25rem;
      padding: 0 2rem;
      font-weight: 600;
    }

    .headerLarge-wallet-connected{

      display: flex;
      align-items: center;
      height: 2.5rem;
      padding: 0 0.5rem;
      padding-right: 1rem;
      color: black;

      .headerLarge-wallet-connected-logo{
        position: relative;
        width: 1.75rem;
        height: 1.75rem;
        border-radius: 1rem;
        margin-right: 0.75rem;
        overflow: hidden;
      }

      .headerLarge-wallet-connected-details{
        font-size: 0.6875rem;
        line-height: 1;
      }

      .headerLarge-wallet-connected-balance{
        margin-bottom: 0.25rem;
        font-weight: 750;
      }

      .headerLarge-wallet-connected-shortAddress{
        font-weight: 400;
      }

    }

  }

  .wallet-adapter-dropdown-list{

    width: 12rem;
    grid-row-gap: 0;
    padding: 1rem 0;
    border-radius: 0;
    box-shadow: none;
    border-radius: 0.5rem;
    background: ${color.gray(98)};
    transform: translate(0.5rem, 1rem);

    .wallet-adapter-dropdown-list-item{

      color: ${color.gray(40)};
      font-family: ${theme.typography.fontFamilyDefault};
      font-size: 0.875rem;
      font-weight: 600;
      border-radius: 0;

      &:not([disabled]):hover{
        background-color: ${color.gray(90)};
      }
    }

    &.wallet-adapter-dropdown-list-active{
      transform: translate(0.5rem, 0.25rem);
    }

  }


`
