
import { useEffect } from 'react'

const useClickOutside = (ref, callback, ignoredClass?) => {

  useEffect(() => {
    
    const listener = (event) => {

      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target) || event.target.closest(`.${ignoredClass}`) !== null) {
          return
      }
      
      callback(event)
        
    }

    document.addEventListener("mousedown", listener)
    document.addEventListener("touchstart", listener)

    return () => {
      document.removeEventListener("mousedown", listener)
      document.removeEventListener("touchstart", listener)
    }

  },[ref, callback])

}

export default useClickOutside

