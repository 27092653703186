import React, { } from 'react';
import styled from 'styled-components';

import Header from 'components/layout/header/header';
import Footer from 'components/layout/footer/footer';
import MainMenuDrawer from 'components/layout/mainMenuDrawer/mainMenuDrawer'

import theme, {bp, color} from 'settings/theme';
import { Category } from '@prisma/client';


type Props = {
  templateData: TemplateData,
  children?: React.ReactNode
};

// Component
const MainLayout: React.FC<Props> = (props) => {

  return (
    <StyledLayout className="mainLayout">

      <div className="mainLayout-mainMenuDrawer">
        <MainMenuDrawer topLevelCategories={props.templateData?.topLevelCategories} />
      </div>

      <div className="mainLayout-header">
        <Header />
      </div>

      <div className="mainLayout-main">
        <div className="mainLayout-page">
          {props.children}
        </div>

        <div className="mainLayout-footer">
          <Footer />
        </div>
      </div>

    </StyledLayout>
  );
};

export default MainLayout;



const StyledLayout = styled.div`

  overflow-x: hidden;
  width: 100%;

  .mainLayout-mainMenuDrawer{
    z-index: 2;
    flex: 0 0 auto;
    width: 0;
  }

  .mainLayout-header{
    position: relative;
    z-index: 1;
  }

  .mainLayout-main{
    display: flex;
    flex-direction: column;
    min-height: calc(100svh - 3.5rem);
    min-height: calc(100vh - 3.5rem);
  }

  .mainLayout-page{
    flex: 1 1 auto;
    position: relative;
    z-index: 0;
  }

  .mainLayout-footer{
    flex: 0 0 auto;
  }

  @media (min-width: ${bp(3)}) {

    .mainLayout-main{
      min-height: calc(100svh - 5rem);
      min-height: calc(100vh - 5rem);
    }

  }

`;
