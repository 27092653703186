import React, { } from 'react';
import Link from 'next/link';
import styled from 'styled-components';

import useLayout from 'providers/layout/layoutProvider';
import MaxWidthWrapper from 'components/layout/maxWidthWrapper';
import HeaderButtons from 'components/layout/header/headerButtons';

import theme, {bp, color} from 'settings/theme';
import WalletButton from 'components/layout/header/headerWallet';

const Header: React.FC<{}> = (props) => {

  const layout = useLayout();


  // Handle menu button click
  const handleMenuClick = () => {
    layout.setIsMainMenuOpen(!layout.isMainMenuOpen);
  };

  return (
    <StyledHeader className="header">

      {/* Fake header for fixed positioning */}
      <div className="header-fake" />

      {/* Fixed header */}
      <div className="header-float">
        <MaxWidthWrapper className="header-wrapper">

          <div className="header-main">
            <Link href="/">
              <img src="/images/logo.svg" alt="The Ormuz Logo" />
            </Link>
          </div>

          <div className="header-right">

            {/* Buttons */}
            <div className="header-buttons">
              <HeaderButtons />
            </div>

            {/* Wallet button */}
            <div className="header-walletButton">
              <WalletButton />
            </div>

            {/* Menu button */}
            <div className="header-menuButton">
              <button onClick={handleMenuClick} className="menuButton-ignore">
                {layout.isMainMenuOpen ? (
                  <img src="/images/icons/icon-cross-white.svg" alt="Menu" style={{transform: 'scale(0.8)'}} />
                ) : (
                  <img src="/images/icons/icon-hamburger-white.svg" alt="Menu" />
                )}
              </button>
            </div>

          </div>
        </MaxWidthWrapper>
      </div>

    </StyledHeader>
  );

};

export default Header;


// Header
const StyledHeader = styled.header`

  .header-fake{
    height: 3.5rem;
  }

  .header-float{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: 3.5rem;
    background-color: ${color.gray(15)};
  }

  .header-wrapper{
    display: flex;
    align-items: center;
    height: 100%;
  }

  .header-main {

    flex: 1 1 auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    padding-left: 1rem;

    img{
      width: auto;
      height: 2.25rem;
    }
    
  }

  .header-right{
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
  }

  .header-menuButton{

    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.5rem;
    height: 100%;
    background-color: ${color.gray(15)};
    transition: background-color 0.15s ease-out;

    button{
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      background-color: ${color.gray(15)};
      transition: background-color 0.15s ease-out;

      &:hover{
        background-color: ${color.gray(25)};
      }
    }

    img{
      width: 1rem;
      height: 1rem;
    }

  }

  .header-walletButton{
    display: none;
  }


  @media (min-width: ${bp(2)}) {
    .header-bagButton{
      right: 0;
    }
  }


  @media (min-width: ${bp(3)}) {

    .header-fake{
      height: 5rem;
    }

    .header-float{
      height: 5rem;
    }

    .header-wrapper{
      padding: 0 1.5rem;
      height: 100%;
    }

    .header-main{
      flex: 0 0 auto;
      justify-content: flex-start;
      padding:0;
    }

    .header-right{
      flex: 1 1 auto;
      width: auto;
    }

    .header-walletButton{
      display: flex;
      align-items: center; 
      margin-left: 1rem;
    }

    .header-menuButton{
      display: none;
    }

  }

`