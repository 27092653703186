import React, { ReactElement, useEffect, useState } from 'react';
import Link from 'next/link';
import { trpc } from 'utils/trpc';
import styled, { css } from 'styled-components';

import theme, {bp, color} from 'settings/theme';


type Props = {};

const HeaderButtons: React.FC<Props> = (props) => {

  // Get Cart
  const cartQuery = trpc.carts.getCurrent.useQuery({store_id: parseInt(process.env.NEXT_PUBLIC_STORE_ID as string)});
  const cart = cartQuery.data;

  return (
    <StyledHeaderButtons className="headerButtons">

      <nav>
        <ul>
          <li className="headerButtons-button">
            <Link href="https://discord.gg/gobi" target="_blank">
              <img src="/images/icons/icon-discord-white.svg" alt="Menu" />
            </Link>
          </li>
          <li className="headerButtons-button">
            <Link href="https://twitter.com/The_Ormuz" target="_blank">
              <img src="/images/icons/icon-twitter-white.svg" alt="Menu" />
            </Link>
          </li>
          <li className="headerButtons-button">
            <Link href="https://gobi.so" target="_blank">
              <img src="/images/icons/icon-gobi-color.svg" alt="Menu" />
            </Link>
          </li>
          <li className="headerButtons-button">
            <Link href="/your-shopping-bag">
              {((cart?.item_count ?? 0) > 0) && 
                <span className="headerButtons-number">
                  {cart?.item_count}
                </span>
              }
              <img src="/images/icons/icon-bag2-white.svg" alt="Menu" />
            </Link>
          </li>
        </ul>
      </nav>

    </StyledHeaderButtons>
  );

};

export default HeaderButtons;

const StyledHeaderButtons = styled.div<{isConnected: boolean}>`

  display: flex;

  ul{
    display: flex;
    height: 100%;
  }

  .headerButtons-button{

    display: flex;
    align-items: center;
    justify-content: center;

    a, button{

      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.25rem;
      height: 2.25rem;
      border-radius: 50%;
      background-color: ${color.gray(15)};
      transition: background-color 0.25s ease;

      &:hover{
        background-color: ${color.gray(20)};
      }

      img{
        width: 1rem;
        height: 1rem;
      }

      .headerButtons-number{
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        min-width: 1rem;
        height: 1rem;
        padding: 0 0.35rem 0 0.25rem;
        font-size: 0.625rem;
        font-weight: 750;
        border-radius: 0.5rem;
        background-color: #e23737;
      }

    }

  }

  @media (min-width: ${bp(3)}) {
    
    ul{
      gap: 0.5rem;
    }

  }

`
