import React, { useState, useMemo, Dispatch, SetStateAction, useEffect } from 'react';
import Link from 'next/link';
import styled, { css } from 'styled-components';

import uselayout from 'providers/layout/layoutProvider'

import theme, { bp, color } from 'settings/theme';
import { useRouter } from 'next/router';


type Props = {
  text: string,
  href: string,
  target?: string
};


// Component
const MainMenuItem: React.FC<Props> = (props) => {
  
  const router = useRouter();
  const layout = uselayout();

  const handleItemClick = () => {
    layout.setIsMainMenuOpen(false);
  };

  return (
    <StyledMainMenuItem className="mainMenuItem">
      <li>
        <Link href={props.href} target={props.target ?? '_self'} onClick={handleItemClick}>
          <div className="mainMenuItem-button">
            {props.text}
          </div>
        </Link>
      </li>
    </StyledMainMenuItem>
  );

};

export default MainMenuItem;

const StyledMainMenuItem = styled.div<{}>`

  a{
    display: inline-block;
    text-decoration: none;
  }

  .mainMenuItem-button{

    display: flex;
    align-items: center;
    width: 100%;
    height: 2rem;
    font-size: 0.925rem;
    font-weight: 650;
    opacity: 0.75;
    transition: opacity 0.2s ease-in-out;

    &:hover{
      opacity: 1;
    }

  }

  @media (min-width: ${bp(2)}) {

    .mainMenuItem-button{
      height: 2.5rem;
      font-size: 0.95rem;
    }

  }

`
