import React, { ReactElement, useState, useMemo } from 'react';
import Link from 'next/link';
import styled, { css } from 'styled-components';
import { Category } from '@prisma/client';

import MainMenuDrawerContainer from 'components/layout/mainMenuDrawer/mainMenuDrawerContainer';
import MainMenuDrawerItem from 'components/layout/mainMenuDrawer/mainMenuItem';
import SimpleButton from 'components/common/buttons/simpleButton';
import MainMenuWallet from './mainMenuWallet';

import useLayout from 'providers/layout/layoutProvider';

import theme, { bp, color } from 'settings/theme';
import { useRouter } from 'next/router';



type Props = {
  topLevelCategories: Category[]
};

const MainMenuStyled: React.FC<Props> = (props) => {

  const layout = useLayout();
  const router = useRouter();

  return (
    <MainMenuDrawerContainer>
      <StyledMainMenuDrawer className="mainMenuDrawer">

        <div className="mainMenuDrawer-topMenu">
          <div className="mainMenuDrawer-topMenu-title">
            Shop
          </div>
          <ul className="mainMenuDrawer-topMenu-items">
            {props.topLevelCategories && props.topLevelCategories.map((item) => {
              return (
                <MainMenuDrawerItem
                  key={item.category_id}
                  text={item.name}
                  href={`/shop/${item.slug}`}
                />
              )
            })
            }
          </ul>
        </div>

        <div className="mainMenuDrawer-bottomMenu">
          <ul>
            {/* <li>
              <Link href="#">Settings</Link>
            </li>
            <li>
              <Link href="#">Track Order</Link>
            </li>
            <li>
              <Link href="#">FAQ</Link>
            </li> */}
            <li>
              <a href="https://twitter.com/The_Ormuz" target="_blank">Twitter</a>
            </li>
            <li>
              <a href="https://discord.gg/gobi" target="_blank">Discord</a>
            </li>
          </ul>
        </div>
        
        <div className="mainMenuDrawer-buttons">
          <MainMenuWallet />
        </div>

      </StyledMainMenuDrawer>
    </MainMenuDrawerContainer>
  );

};

export default MainMenuStyled;

const StyledMainMenuDrawer = styled.div<{}>`

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  .mainMenuDrawer-topMenu{

    flex: 1 1 auto;
    margin-top: 1rem;
    padding: 1rem 1rem 0 1rem;
    overflow-y: auto;

    .mainMenuDrawer-topMenu-title{
      font-size: 1.25rem;
      font-weight: 650;
    }

    .mainMenuDrawer-topMenu-items{
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
    }

  }

  .mainMenuDrawer-bottomMenu{

    flex: 0 0 auto;
    margin-top: 2rem;
    padding: 0 1rem;

    a{
      display: inline-block;
      height: 2rem;
      color: ${color.gray(75)};
      font-size: 0.825rem;
      font-weight: 650;
      text-decoration: none;
    }

  }

  .mainMenuDrawer-buttons{
    flex: 0 0 auto;
    margin-top: 1.5rem;
    padding: 0 1rem 1rem 1rem;
  }

  @media (min-width: ${bp(2)}) {

    .mainMenuDrawer-topMenu{

      padding: 2rem 2rem 0 2rem;

      .mainMenuDrawer-topMenu-title{
        font-size: 1.5rem;
      }

    }

    .mainMenuDrawer-bottomMenu{

      padding: 0 2rem;

      a{
        height: 2rem;
        font-size: 0.875rem;
      }

    }

    .mainMenuDrawer-buttons{
      margin-top: 2rem;
      padding: 0 2rem 2rem 2rem;
    }

  }

`
