import React, { } from 'react';
import Link from 'next/link';
import styled from 'styled-components';

import useLayout from 'providers/layout/layoutProvider';
import MaxWidthWrapper from 'components/layout/maxWidthWrapper';

import theme, {bp, color} from 'settings/theme';


const Footer: React.FC<{}> = (props) => {

  const layout = useLayout();

  // Handle menu button click
  const handleMenuClick = () => {
    layout.setIsMainMenuOpen(!layout.isMainMenuOpen);
  };

  return (
    <StyledFooter className="footer">

      <MaxWidthWrapper className="footer-wrapper">
        <div className="footer-layout">
          <div className="footer-left">
            <ul className="footer-menu">
              {/* <li>
                <Link href="#">Settings</Link>
              </li>
              <li>
                <Link href="#">Track Order</Link>
              </li>
              <li>
                <Link href="#">FAQ</Link>
              </li> */}
              <li>
                <Link href="https://twitter.com/The_Ormuz" target="_blank">Twitter</Link>
              </li>
              <li>
                <Link href="https://discord.gg/gobi" target="_blank">Discord</Link>
              </li>
            </ul>
            <div className="footer-logo">
              <img src="/images/logo-light.svg" alt="The Ormuz Logo" />
            </div>
          </div>
          <div className="footer-right">
            <div className="footer-copyright">
              &copy;ORMUZ 2023 | All Rights Reserved<br />
              <span className="footer-copyright-gobi">POWERED BY GOBI LABS</span>
            </div>
          </div>
        </div>
      </MaxWidthWrapper>

    </StyledFooter>
  );

};

export default Footer;


// Footer
const StyledFooter = styled.footer`

  background-color: ${color.gray(33.3)};

  .footer-layout{
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }

  .footer-left{
    flex: 1 1 auto;
  }

  .footer-right{
    flex: 1 1 auto;
  }

  .footer-menu{

    display: flex;
    flex-direction: column;
    gap: 1rem;

    a{

      display: inline-flex;
      font-size: 0.85rem;
      font-weight: 600;
      text-decoration: none;

      &:hover{
        text-decoration: underline;
      }

    }

  }

  .footer-logo{

    margin-top: 3rem;

    img{
      width: auto;
      height: 2rem;
    }

  }

  .footer-copyright{

    margin-top: 1rem;
    font-size: 0.675rem;
    font-weight: 600;
    line-height: 1.2;

    .footer-copyright-gobi{
      display: block;
      margin-top: 0.5rem;
      opacity: 0.5;
    }

  }


  @media (min-width: ${bp(3)}) {

    .footer-layout{
      flex-direction: row;
      padding: 1.5rem;
      padding-bottom: 2rem;
    }

    .footer-menu{

      a{
        font-size: 1rem;
      }

    }

    .footer-copyright{
      margin-top: 0;
      font-size: 0.75rem;
      line-height: 1.2;
    }

    .footer-right{
      align-self: flex-end;
      display: flex;
      justify-content: flex-end;
      text-align: right;
    }

  }

`