import React, { ReactElement, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import styled, { css } from 'styled-components';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';

import currencies from 'data/currencies';
import SimpleButton from 'components/common/buttons/simpleButton';

import theme, {bp, color} from 'settings/theme';

const ConnectWalletButton = dynamic(() => import('components/common/wallet/walletButton'), {
  ssr: false
});

const LAMPORTS_PER_SOL = 1000000000;

type Props = {};

const MainMenuWallet: React.FC<Props> = (props) => {

  const { connected, connecting, publicKey, disconnect } = useWallet();
  const {connection} = useConnection();
  const [balance, setBalance] = useState<string>('5');
  const [isBalanceChecking, setIsBalanceChecking] = useState(false);

  const fetchWalletBalance = async () => {
    if (connected && publicKey){
      
      setIsBalanceChecking(true);

      try {
        
        const { PublicKey } = (await import('@solana/web3.js'));
        const TOKEN_PROGRAM_ID = new PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA");

        setBalance('0');

        const parsedTokenAccounts = await connection.getParsedTokenAccountsByOwner(publicKey, {
          programId: TOKEN_PROGRAM_ID
        });

        const accounts = parsedTokenAccounts.value.map(account => account.account.data.parsed.info);
        const mint = currencies.find(x => x.tokenSymbol === 'usdc')?.mint;
        const balance = accounts.find(x => x.mint === mint)?.tokenAmount?.uiAmount ?? 0;
        setBalance(balance.toFixed(2));

      } catch (error) {

        // Handle balance load error

      }  finally {
        setIsBalanceChecking(false);
      }
      
    }
  }



  useEffect(() => {
    fetchWalletBalance(); 
  }, [publicKey]);

  const getWalletLogoUrl = (walletName: string) => {

    switch (walletName?.toLowerCase()) {
      case 'phantom':
        return '/images/logos/logo-phantom-square.svg';
      case 'solflare':
        return '/images/logos/logo-solflare-square.svg';
      case 'slope':
        return '/images/logos/logo-slope-square.svg';
      default:
        return '/images/logos/logo-unknown-wallet-square.svg';
    }

  };

  // Handle Disconnect
  const handleDisconnectClick = (e) => {
    e.preventDefault();
    disconnect();
  };

  return (
    <StyledMainMenuWallet isConnected={connected} className="mainMenuWallet">

      <ConnectWalletButton>
        {({connecting, connected, shortAddress, wallet}) => (
        
          <>
            {connecting ? (

                <div className="headerLarge-wallet-connecting">
                  Connecting...
                </div>
                
              ) : !!wallet && connected ? (

                <div className="headerLarge-wallet-connectedLayout">
                  <div className="headerLarge-wallet-disconnect">
                    <SimpleButton as="a" onClick={handleDisconnectClick}>
                      Disconnect
                    </SimpleButton>
                  </div>
                  <div className="headerLarge-wallet-connected">
                    <div className="headerLarge-wallet-connected-logo">
                      <Image src={getWalletLogoUrl(wallet.adapter.name)} fill={true} sizes="0vw" alt="Wallet Logo" />
                    </div>
                    <div className="headerLarge-wallet-connected-details">
                      <div className="headerLarge-wallet-connected-balance">
                        {isBalanceChecking ? (
                          <>Checking...</>
                        ) : (
                          <>{balance} USDC</>
                        )}
                      </div>
                      <div className="headerLarge-wallet-connected-shortAddress">
                        {shortAddress}
                      </div>
                    </div>
                    
                  </div>
                </div>

              ) : (

                <div className="headerLarge-wallet-notConnected">
                  Connect Wallet
                </div>

              )
            }
          </>

        )}
        
      </ConnectWalletButton>

    </StyledMainMenuWallet>
  );

};

export default MainMenuWallet;

const StyledMainMenuWallet = styled.div<{isConnected: boolean}>`

  --connect-wallet-button-height: 2.5rem;
  --connect-wallet-button-font-size: 0.875rem;
  --connect-wallet-button-color: transparent;
  --connect-wallet-button-hover-color: transparent;
  --connect-wallet-button-border-radius: 0;

  position: relative;
  width: 100%;
  height: 100%;

  .wallet-adapter-dropdown{
    width: 100%;
    height: 100%;
  }

  button{

    justify-content: center;
    width: 100%;
    padding: 0;

    .headerLarge-wallet-connecting, .headerLarge-wallet-notConnected{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 3rem;
      color: black;
      font-size: 0.925rem;
      font-weight: 750;
      border-radius: 0.5rem;
      background-color: ${color.gray(90)};
      transition: background-color 0.15s ease-in-out;

      &:hover{
        background-color: ${color.gray(100)};
      }

    }

    .headerLarge-wallet-connectedLayout{
      display: flex;
      align-items: center;
      gap: 1rem;
      width: 100%;
      height: 100%;
    }

    .headerLarge-wallet-disconnect{
      flex: 1 1 auto;
      width: 60%;

      a{
        border-radius: 0.5rem;
        background-color: ${color.green(50)};
      }

    }

    .headerLarge-wallet-connected{

      flex: 0 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40%;
      height: 100%;
      color: black;
      padding: 0;
      border-radius: 0.5rem;
      background-color: ${color.gray(100)};

      .headerLarge-wallet-connected-logo{
        position: relative;
        width: 1.75rem;
        height: 1.75rem;
        border-radius: 1rem;
        margin-right: 0.75rem;
        overflow: hidden;
      }

      .headerLarge-wallet-connected-details{
        font-size: 0.6875rem;
        line-height: 1;
      }

      .headerLarge-wallet-connected-balance{
        margin-bottom: 0.25rem;
        font-weight: 750;
      }

      .headerLarge-wallet-connected-shortAddress{
        font-weight: 400;
      }

    }

  }

  .wallet-adapter-dropdown-list{

    top: auto;
    bottom: 100%;
    width: 12rem;
    grid-row-gap: 0;
    padding: 1rem 0;
    border-radius: 0.5rem;
    box-shadow: none;
    background: ${color.gray(98)};
    transform: translate(0.5rem, 0.25rem);

    .wallet-adapter-dropdown-list-item{

      color: ${color.gray(0)};
      font-family: ${theme.typography.fontFamilyDefault};
      font-size: 0.875rem;
      font-weight: 750;
      border-radius: 0;

      &:not([disabled]):hover{
        background-color: ${color.gray(90)};
      }
    }

    &.wallet-adapter-dropdown-list-active{
      transform: translate(0.5rem, -0.55rem);
    }

  }


  @media (min-width: ${bp(2)}) {

    --connect-wallet-button-height: 3rem;

  }


`
